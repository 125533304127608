<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :height="`${height}px` || '24px'" :width="`${width}px` || '24px'">
    <path
      d="M12 16.8457C11.8795 16.8457 11.7611 16.8142 11.6565 16.7544L5.59636 13.2908C5.54371 13.2604 5.48397 13.2444 5.42316 13.2444C5.36236 13.2445 5.30263 13.2605 5.25 13.2909C5.19736 13.3214 5.15368 13.3651 5.12333 13.4178C5.09299 13.4705 5.07706 13.5303 5.07715 13.5911V16.8457C5.07705 16.9693 5.11001 17.0906 5.17263 17.1971C5.23525 17.3036 5.32523 17.3914 5.43324 17.4514L11.6638 20.9129C11.7667 20.97 11.8824 21 12 21C12.1177 21 12.2334 20.97 12.3362 20.9129L18.5668 17.4514C18.6748 17.3914 18.7648 17.3036 18.8274 17.1971C18.89 17.0906 18.923 16.9693 18.9229 16.8457V13.5911C18.923 13.5303 18.907 13.4705 18.8767 13.4178C18.8464 13.3651 18.8027 13.3214 18.75 13.2909C18.6974 13.2605 18.6377 13.2445 18.5769 13.2444C18.5161 13.2444 18.4563 13.2604 18.4037 13.2908L12.3436 16.7544C12.2389 16.8142 12.1205 16.8457 12 16.8457Z"
      :fill="color"
    />
    <path
      d="M22.3808 9.16564V9.16088C22.3696 9.05114 22.3324 8.94567 22.2721 8.85325C22.2119 8.76083 22.1305 8.68413 22.0347 8.62955L12.3426 3.09126C12.238 3.03146 12.1196 3 11.9991 3C11.8786 3 11.7602 3.03146 11.6556 3.09126L1.96354 8.62955C1.85762 8.69011 1.76958 8.77759 1.70835 8.88314C1.64712 8.98868 1.61487 9.10852 1.61487 9.23054C1.61487 9.35256 1.64712 9.47241 1.70835 9.57795C1.76958 9.68349 1.85762 9.77097 1.96354 9.83153L11.6556 15.3698C11.7602 15.4296 11.8786 15.4611 11.9991 15.4611C12.1196 15.4611 12.238 15.4296 12.3426 15.3698L20.8699 10.4974C20.8831 10.4898 20.898 10.4858 20.9132 10.4858C20.9285 10.4858 20.9434 10.4899 20.9566 10.4975C20.9697 10.5051 20.9807 10.5161 20.9882 10.5293C20.9958 10.5425 20.9998 10.5575 20.9997 10.5727V16.8262C20.9997 17.1988 21.2861 17.5185 21.6587 17.5371C21.7523 17.5416 21.8458 17.5271 21.9336 17.4944C22.0214 17.4617 22.1017 17.4115 22.1695 17.3468C22.2374 17.2822 22.2914 17.2044 22.3283 17.1183C22.3652 17.0321 22.3843 16.9394 22.3843 16.8457V9.23054C22.3842 9.20886 22.3831 9.1872 22.3808 9.16564Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
